<template>
  <v-dialog
    v-if="
      lead.multi_sales_opportunities
        ? lead.multi_sales_opportunities.length != 0
        : false
    "
    v-model="dialog"
    width="900"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip bottom max-width="200">
        <template #activator="{ on: tooltipOn }">
          <v-btn
            elevation="1"
            small
            class="amber lighten-3 mr-2"
            icon
            v-bind="attrs"
            v-on="{ ...on, ...tooltipOn }"
            @click="showModal"
          >
            <v-icon small color="#CC9900">
              mdi-trophy
            </v-icon>
          </v-btn>
        </template>
        <span>
          Este Lead fue creado a partir de una venta exitosa (Click para ver
          mas).
        </span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Multi Oportunidad
      </v-card-title>
      <v-spacer></v-spacer>
      <v-row>
        <v-col>
          <p class="text-subtitle-2 text-center">
            Lead creado
          </p>
          <div
            class="border-default card auto lead-card-container"
            :class="userRole === 'marketing' ? 'no-hover-pointer' : ''"
            @click="setActualLead(lead._id)"
          >
            <lead-card
              :lead="lead"
              :contactLeadName="lead.contact_lead_name"
              :srcBrokerImage="
                lead.internal_broker
                  ? brokerImageFormat(lead.internal_broker)
                  : brokerImageFormat(lead.internal_admin)
              "
              :brokerName="getBrokerName(lead)"
              :observations="lead.observations"
              :observations_detail="lead.observations_detail"
              :budget="parseInt(lead.budget)"
              :currency="lead.currency"
              :profilePercentage="lead.profile_percentage | percentageFormat"
            />
          </div>
        </v-col>
        <v-divider inset vertical></v-divider>
        <v-col>
          <p class="text-subtitle-2 text-center">
            Lead original(Venta)
          </p>
          <v-skeleton-loader
            v-if="!origin._id"
            class="my-4"
            type="list-item-avatar-two-line,list-item, actions"
          ></v-skeleton-loader>
          <div
            v-else
            class="border-default card auto lead-card-container"
            :class="userRole === 'marketing' ? 'no-hover-pointer' : ''"
            @click="setActualLead(origin._id)"
          >
            <lead-card
              :lead="origin"
              :contactLeadName="origin.contact_lead_name"
              :srcBrokerImage="
                origin.internal_broker
                  ? brokerImageFormat(origin.internal_broker)
                  : brokerImageFormat(origin.internal_admin)
              "
              :brokerName="getBrokerName(origin)"
              :observations="origin.observations"
              :observations_detail="origin.observations_detail"
              :budget="parseInt(origin.budget)"
              :currency="origin.currency"
              :profilePercentage="origin.profile_percentage | percentageFormat"
            />
          </div>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="dialog = false">
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
    <lead-detail-modal
      ref="leadDetailModal"
      :page="'kanban'"
      :dialog="isDialogActive"
      @setDialog="setDialog"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { GetLeadDetail } from "@/api/lead-api.js";

export default {
  components: {
    LeadCard: () => import("@/components/lead/LeadCard.vue"),
    LeadDetailModal: () => import("@/components/lead/LeadDetailModal.vue")
  },
  props: {
    lead: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      dialog: false,
      isDialogActive: false,
      origin: {},
      userRole: localStorage.getItem("user_role") || ""
    };
  },
  methods: {
    ...mapMutations({
      setLoading: "leads/setLoading",
      setFiltersSelectedSt: "leads/setFiltersSelected"
    }),
    ...mapActions({
      fetchActualLead: "leads/fetchActualLead",
      fetchGetLeadsKanbanByPhaseSt: "leads/fetchGetLeadsKanbanByPhase",
      fetchMoreLeadsKanbanByPhaseSt: "leads/fetchMoreLeadsKanbanByPhase",
      fetchRealEstateListStore: "realEstateDevelopments/getRealEstateDevAssgign"
    }),
    showModal() {
      //this.lead.multi_sales_opportunities = ["643593462f4423f2ebba4c0a"];
      GetLeadDetail(this.lead.multi_sales_opportunities[0]).then(res => {
        this.origin = res.Lead;
      });
    },
    brokerImageFormat(broker) {
      if (!(broker && broker.name)) return "";
      let brokerSrcImg = "";
      let brokerName = "";
      if (broker.name) brokerName = broker.name;
      if (
        broker.media &&
        broker.media.featured_image &&
        broker.media.featured_image.src
      )
        brokerSrcImg = broker.media.featured_image.src;
      if (!brokerName) brokerName = "C28";
      if (!brokerSrcImg)
        brokerSrcImg =
          "https://dummyimage.com/400x200/262d3c/fff&text=" +
          brokerName.substring(0, 2);

      return brokerSrcImg;
    },
    getBrokerName(lead) {
      if (lead.internal_broker) {
        return lead.internal_broker.name;
      } else if (lead.internal_admin) {
        return lead.internal_admin.name;
      } else {
        return "";
      }
    },
    setDialog(isActive) {
      this.isDialogActive = isActive;
    },
    setActualLead(leadId) {
      // si es usuario es marketing no realizar ninguna acción
      if (this.userRole !== "marketing") {
        let self = this;
        self.setLoading(true);
        self.setDialog(true);
        // resetear leads seleccionados (checkboxes)
        self.selectedItems = [];
        this.fetchActualLead(leadId)
          .then()
          .finally(() => {
            setTimeout(() => {
              self.setLoading(false);
            }, 1000);
          });
      }
    }
  },
  filters: {
    percentageFormat(percentage) {
      if (!percentage || isNaN(percentage)) return 0;
      let percentageParsed = parseInt(percentage);
      return percentageParsed;
    }
  }
};
</script>
